import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useEffect } from "react";

import EmailConfirmationMessage from "./EmailConfirmationMessage";

import Loader from "./Loader";

/**
 * We need this messages:
 * - Return could not be posted into ExitB: "Your return could not be registered ....."
 * - Sth. went wrong with Label (could not be sent/generated)
 *
 */

const Confirmation = ({ ups, dhl, dhlToUps, emailSent, email, labelSupport, supportError, isLoading, terminate, commentPosted, exceptionSwitzerland }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  if (emailSent && commentPosted) {
    sessionStorage.setItem("completed", true);
  }

  if (ups) {
    sessionStorage.setItem("ups", JSON.stringify(ups));
  }

  useEffect(() => {
    if (state === null) {
      navigate(1);
    }
  });

  return (
    <div className="confirmation-container">
      {isLoading && <Loader />}
      {!labelSupport && !isLoading && emailSent && commentPosted && <EmailConfirmationMessage ups={ups} dhl={dhl} dhlToUps={dhlToUps} email={email} />}
      {supportError && !isLoading && !sessionStorage.getItem("completed") && (
        <div className="my-4">
          {t("confirmationView.labelError.text1")}&nbsp;
          <a href="mailto:info@gusti-leder.de">info@gusti-leder.de</a>&nbsp;
        </div>
      )}
      {labelSupport && !exceptionSwitzerland && !isLoading && <div className="my-4">{t("confirmationView.support.text")}</div>}
      {labelSupport && exceptionSwitzerland && !isLoading && (
        <div className="my-4">
          {t("confirmationView.exception.ch1")}&nbsp;
          <a href="mailto:info@gusti-leder.de">info@gusti-leder.de</a>&nbsp;
          {t("confirmationView.exception.ch2")}
        </div>
      )}
      {!isLoading && (
        <Button className="p-1 btn" variant="dark" onClick={terminate}>
          {t("buttons.terminate")}
        </Button>
      )}
    </div>
  );
};

export default Confirmation;
