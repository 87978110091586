import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LabelLinkMessage from "./LabelLinkMessage";

const EmailConfirmationMessage = ({ ups, dhl, dhlToUps, email }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  window.onpopstate = function (event) {
    navigate(1);
  };
  return (
    <div>
      {ups && (
        <div>
          <LabelLinkMessage url={sessionStorage.getItem("upsLink")} />
          <div className="p-4 my-3 success-msg">
            <h6>{t("confirmationView.package.heading.general")} </h6>
            <p>{t("confirmationView.package.text.general")}</p>
            <h6>{t("confirmationView.label.heading.general")} </h6>
            <p>{t("confirmationView.label.text.ups")}</p>
            <h6>{t("confirmationView.shipping.heading.general")} </h6>
            <p>{t("confirmationView.shipping.text.ups")}</p>
          </div>
        </div>
      )}
      {dhl && (
        <div>
          <div className="my-5">
            <p className="lead">{t("confirmationView.email.information", { email: `${email}` })}</p>
            <p className="lead">{t("confirmationView.label.instruction")}</p>
          </div>
          <div className="p-4 my-3 success-msg">
            <h6>{t("confirmationView.package.heading.general")} </h6>
            <p>{t("confirmationView.package.text.general")}</p>
            <h6>{t("confirmationView.label.heading.general")} </h6>
            <p>{t("confirmationView.label.text.dhl")}</p>
            <h6>{t("confirmationView.shipping.heading.general")} </h6>
            <p>{t("confirmationView.shipping.text.dhl")}</p>
          </div>
        </div>
      )}
      {dhlToUps && (
        <div>
          <div className="my-5">
            <p className="lead">{t("confirmationView.email.information", { email: `${email}` })}</p>
            <p className="lead">{t("confirmationView.label.instruction")}</p>
          </div>
          <div className="p-4 my-3 success-msg">
            <h6>{t("confirmationView.package.heading.general")} </h6>
            <p>{t("confirmationView.package.text.general")}</p>
            <h6>{t("confirmationView.label.heading.general")} </h6>
            <p>{t("confirmationView.label.text.customUps")}</p>
            <h6>{t("confirmationView.shipping.heading.general")} </h6>
            <p>{t("confirmationView.shipping.text.ups")}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailConfirmationMessage;

// : (
//   <div>
//     <div className="my-5">
//       <p className="lead">{t("confirmationView.email.information", { email: `${email}` })}</p>
//       <p className="lead">{t("confirmationView.label.instruction")}</p>
//     </div>
//     <div className="p-4 my-3 success-msg">
//       <h6>{t("confirmationView.package.heading.general")} </h6>
//       <p>{t("confirmationView.package.text.general")}</p>
//       <h6>{t("confirmationView.label.heading.general")} </h6>
//       <p>{t("confirmationView.label.text.dhl")}</p>
//       <h6>{t("confirmationView.shipping.heading.general")} </h6>
//       <p>{t("confirmationView.shipping.text.dhl")}</p>
//     </div>
//   </div>
// )
