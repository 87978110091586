import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

const CancelBtn = ({ handleCancelClick, containsCheckedItems }) => {
  const { t } = useTranslation();
  return (
    <Button className={containsCheckedItems ? "p-1 btn cancel-btn m-right" : "p-1 btn cancel-btn"} onClick={handleCancelClick}>
      {t("buttons.cancel")}
    </Button>
  );
};

export default CancelBtn;
