const Checkbox = ({ isReturnable, toggleCheck, index }) => {
  let isHidden;

  isReturnable ? (isHidden = false) : (isHidden = true);

  return (
    <div className="check-container">
      <input className="checkbox " type="checkbox" onChange={() => toggleCheck(index)} hidden={isHidden} />
    </div>
  );
};

export default Checkbox;
