import { Form } from "react-bootstrap";

const QuantityDropdown = ({ quantity, handleQuantitySelect, index }) => {
  const quantityArray = [];
  for (let i = 1; i <= quantity; i++) {
    quantityArray.push(i);
  }

  return (
    <div className="quantity-dropdown">
      <Form.Select size="sm" aria-label="quantity select" onChange={(e) => handleQuantitySelect(e, index)}>
        {quantityArray.map((value) => {
          return (
            <option value={value} key={value}>
              {value}
            </option>
          );
        })}
      </Form.Select>
    </div>
  );
};

export default QuantityDropdown;
