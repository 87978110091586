import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import NotReturnableMessage from "./NotReturnableMessage";
import ReasonsDropdown from "./ReasonsDropdown";
import QuantityDropdown from "./QuantityDropdown";
import Checkbox from "./Checkbox";

const ArticleCard = ({ article, toggleCheck, index, checkedStatus, handleReturnReasonSelect, handleQuantitySelect, missingReasonList }) => {
  const { t } = useTranslation();
  let isReturnable = article.isReturnable;
  // let missingReason = false;
  // if (missingReasonList && missingReasonList.length) {
  //   if (missingReasonList.some((art) => art.articleNumber === article.articleNumber)) missingReason = true;
  // }

  return (
    <div className="my-5 card-container">
      {isReturnable ? <Checkbox toggleCheck={toggleCheck} index={index} isReturnable={isReturnable} articleNumber={article.articleNumber} /> : <div className="check-container"></div>}

      <Card className="article-card border-0 mb-2" style={{ width: "10rem" }}>
        <Card.Img className="w-100 h-15 " variant="top" src={article.imageUrl} alt={article.description} />
        <Card.Body className="p-1">
          <Card.Text className="py-3">
            <p className={isReturnable ? "m-0" : "m-0 text-muted"}>{article.description}</p>
            <p className={isReturnable ? "m-0" : "m-0 text-muted"}>{article.articleNumber}</p>
            <p className={isReturnable ? "m-0" : "m-0 text-muted"}>
              {t("articleListView.quantity")}: {article.quantityShipped}
            </p>
            {isReturnable && article.returnDeadline && (
              <p>
                <span>{t("articleListView.deadline")}: </span>
                <br />
                {article.returnDeadline.slice(0, 10)}
              </p>
            )}
          </Card.Text>
        </Card.Body>
      </Card>
      {isReturnable ? "" : <NotReturnableMessage notReturnableReasons={article.notReturnableReasons} />}
      <div className="dropdown-container">
        {checkedStatus && <ReasonsDropdown handleReturnReasonSelect={handleReturnReasonSelect} index={index} missingReasonList={missingReasonList} articleNumber={article.articleNumber} />}
        {checkedStatus && article.quantityShipped > 1 && <QuantityDropdown quantity={article.quantityShipped} handleQuantitySelect={handleQuantitySelect} article={article} index={index} />}
      </div>
    </div>
  );
};
export default ArticleCard;
