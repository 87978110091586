import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContinueBtn from "./ContinueBtn";
import CancelBtn from "./CancelBtn";
import ArticleCard from "./ArticleCard";
import MissingReasonAlert from "./MissingReasonAlert";
import { useEffect } from "react";

const ArticleList = ({ articleList, handleCancelClick, toggleCheck, handleReturnReasonSelect, handleQuantitySelect, handleUserCommentChange, handleListSubmit, missingReasonList }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();

  const containsCheckedItems = articleList.some((article) => {
    return article.checked;
  });

  useEffect(() => {
    if (state === null) {
      navigate(1);
    }
  });
  if (articleList.length) {
    return (
      <div className="article-view">
        <div className="list-container">
          <div>
            {articleList.map((article, index) => (
              <div key={index}>
                <ArticleCard article={article} toggleCheck={toggleCheck} index={index} checkedStatus={article.checked} handleReturnReasonSelect={handleReturnReasonSelect} handleQuantitySelect={handleQuantitySelect} missingReasonList={missingReasonList} />
              </div>
            ))}
          </div>
        </div>
        {missingReasonList && missingReasonList.length > 0 && <MissingReasonAlert />}
        <div className="btn-container">
          <CancelBtn handleCancelClick={handleCancelClick} containsCheckedItems={containsCheckedItems} />
          {containsCheckedItems && <ContinueBtn handleListSubmit={handleListSubmit} />}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <p>{t("articleListView.errorMessage.noArticles")}</p>
        <CancelBtn handleCancelClick={handleCancelClick} containsCheckedItems={containsCheckedItems} />
      </div>
    );
  }
};
export default ArticleList;
