import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

const ContinueBtn = ({ handleListSubmit }) => {
  const { t } = useTranslation();
  // const navigate = useNavigate();

  return (
    <Button className="btn" variant="dark" onClick={handleListSubmit}>
      {t("buttons.continue")}
    </Button>
  );
};

export default ContinueBtn;
