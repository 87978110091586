const CustomerCard = ({ firstName, lastName, orderNumber, email, supportLogin }) => {
  return (
    <div className="customer-container border-bottom">
      <h1 className="h1">{orderNumber}</h1>
      <p> {email}</p>
      {supportLogin && <h4 className="support-bg p-2">[Customer Support Login]</h4>}
    </div>
  );
};

export default CustomerCard;
