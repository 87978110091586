import { useTranslation } from "react-i18next";

const NotReturnableMessage = ({ notReturnableReasons }) => {
  const { t } = useTranslation();
  const reasonsArray = [];

  for (let [key, value] of Object.entries(notReturnableReasons)) {
    if (value) {
      reasonsArray.push(key);
    }
  }

  return (
    <div className="not-returnable">
      <ul className="m-0 p-0">
        {reasonsArray.map((reason) => (
          <li className="m-0 p-0">
            <span>{t(`articleListView.errorMessages.${reason}`)}</span>
          </li>
        ))}
      </ul>
      {}
    </div>
  );
};

export default NotReturnableMessage;
