import { useTranslation } from "react-i18next";
// import { Alert } from "react-bootstrap";
//import { useState } from "react";

const WrongInputMessage = () => {
  const { t } = useTranslation();

  return <div className="my-4 p-2 error-msg">{t("loginView.errorMessages.notFound")}</div>;
};

export default WrongInputMessage;
