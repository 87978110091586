import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

const ReasonsDropdown = ({ handleReturnReasonSelect, index, missingReasonList, articleNumber }) => {
  const { t } = useTranslation();

  return (
    <div className="reasons-dropdown">
      <Form.Select aria-label="Reason select" size="sm" onChange={(e) => handleReturnReasonSelect(e, index)}>
        <optgroup label={t("articleListView.reasonsDropdown.orderOption")}>
          <option value="0">{t("articleListView.reasonsDropdown.choseResponse")}</option>
          <option value="1">{t("articleListView.reasonsDropdown.selectionItem")}</option>
          <option value="2">{t("articleListView.reasonsDropdown.incorrectItem")}</option>
          <option value="3">{t("articleListView.reasonsDropdown.doubleItem")}</option>
          <option value="4">{t("articleListView.reasonsDropdown.damagedItem")}</option>
          <option value="5">{t("articleListView.reasonsDropdown.wrongItem")}</option>
        </optgroup>
        <optgroup label={t("articleListView.reasonsDropdown.notLikedOption")}>
          <option value="6">{t("articleListView.reasonsDropdown.formDesign")}</option>
          <option value="7">{t("articleListView.reasonsDropdown.itemColor")}</option>
        </optgroup>
        <optgroup label={t("articleListView.reasonsDropdown.sizeOption")}>
          <option value="8">{t("articleListView.reasonsDropdown.smallItem")}</option>
          <option value="9">{t("articleListView.reasonsDropdown.largeItem")}</option>
        </optgroup>
        <optgroup label={t("articleListView.reasonsDropdown.faultyOption")}>
          <option value="10">{t("articleListView.reasonsDropdown.materialQuality")}</option>
          <option value="11">{t("articleListView.reasonsDropdown.strongOdor")}</option>
          <option value="12">{t("articleListView.reasonsDropdown.wrongItem")}</option>
          <option value="13">{t("articleListView.reasonsDropdown.other")}</option>
          <option value="18">{t("articleListView.reasonsDropdown.incomplete")}</option>
        </optgroup>
      </Form.Select>
    </div>
  );
};

export default ReasonsDropdown;
