import { useTranslation } from "react-i18next";

const TextArea = ({ handleUserCommentChange }) => {
  const { t } = useTranslation();
  return (
    <form className="">
      <label className="w-100">
        <textarea className="w-100 p-2 bg-light small" maxLength={100} placeholder={t("returnListView.userComment")} onChange={(e) => handleUserCommentChange(e)}></textarea>
      </label>
    </form>
  );
};

export default TextArea;
