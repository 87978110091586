import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

const RequestBtn = ({ handleLabelRequest, carrier }) => {
  const { t } = useTranslation();
  let carrierName = "";
  // if (carrier === "DHL") {
  //   carrierName = carrier;
  // }

  return (
    <Button className="p-1 btn" variant="dark" onClick={handleLabelRequest}>
      {t("buttons.requestLabel", { carrier: carrierName })}
    </Button>
  );
};

export default RequestBtn;
