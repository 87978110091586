import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const loadPath = process.env.REACT_APP_i18NEXUS_LOAD_PATH;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    fallbackLng: "en",
    // disabled in production
    debug: false,

    ns: ["Standard"],
    defaultNS: "Standard",
    supportedLngs: ["de", "es", "en"],
    backend: {
      loadPath: loadPath,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ",",
    },
  });

export default i18n;
