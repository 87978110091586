import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { useEffect } from "react";

import RequestBtn from "./RequestBtn";
import TextArea from "./TextArea";

const ReturnList = ({ returnList, orderData, backToArticleList, cancelReturnProcess, handleUserCommentChange, handleLabelRequest }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state === null) {
      navigate(1);
    }
  });

  const returnReasons = [`${t("articleListView.reasonsDropdown.choseResponse")}`, `${t("articleListView.reasonsDropdown.selectionItem")}`, `${t("articleListView.reasonsDropdown.incorrectItem")}`, `${t("articleListView.reasonsDropdown.doubleItem")}`, `${t("articleListView.reasonsDropdown.damagedItem")}`, `${t("articleListView.reasonsDropdown.wrongItem")}`, `${t("articleListView.reasonsDropdown.formDesign")}`, `${t("articleListView.reasonsDropdown.itemColor")}`, `${t("articleListView.reasonsDropdown.smallItem")}`, `${t("articleListView.reasonsDropdown.largeItem")}`, `${t("articleListView.reasonsDropdown.materialQuality")}`, `${t("articleListView.reasonsDropdown.strongOdor")}`, `${t("articleListView.reasonsDropdown.wrongItem")}`, `${t("articleListView.reasonsDropdown.other")}`, `${t("articleListView.reasonsDropdown.incomplete")}`];

  return (
    <div className="return-view">
      <Table className="table-borderless">
        <thead>
          <tr>
            <th className="px-0">{t("returnListView.article")}</th>
            <th className="px-0">{t("returnListView.returnReason")}</th>
            <th className="px-0">{t("returnListView.quantity")}</th>
          </tr>
        </thead>
        <tbody>
          {returnList.map((article, index) => (
            <tr key={index}>
              <td className="px-0">{article.articleNumber}</td>
              <td className="px-0">
                {article.returnReason === 18 ? returnReasons[14] : returnReasons[article.returnReason]} ({article.returnReason})
              </td>
              <td className="px-1">{article.returnQuantity}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <TextArea handleUserCommentChange={handleUserCommentChange} />

      <div className="return-btns">
        {returnList.length !== 0 && <RequestBtn handleLabelRequest={handleLabelRequest} carrier={orderData.carrier} />}
        <Button className="p-1 btn change-btn" onClick={backToArticleList}>
          {t("buttons.changeSelection")}
        </Button>
        <Button className="p-1 btn cancel-btn" onClick={cancelReturnProcess}>
          {t("buttons.cancel")}
        </Button>
      </div>
    </div>
  );
};

export default ReturnList;
