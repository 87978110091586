import { useTranslation } from "react-i18next";

const LabelLinkMessage = ({ url }) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <p>
        <b>
          <a href={url} target="_blank" rel="noreferrer">
            &#128279; {t("confirmationView.link.ups")}
          </a>
        </b>
      </p>
    </div>
  );
};

export default LabelLinkMessage;
